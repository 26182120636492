@import url("https://fonts.googleapis.com/css?family=Noto+Sans|Noto+Serif");

* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: "Noto Sans", sans-serif;
  font-size: 100%;
  background-color: #fafafa;  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::selection {
  color: #ffffff;
  background-color: #283e55;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
